<template>
  <div>
    <validation-observer ref="simpleRules">

      <b-row>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                รายละเอียด WH-519
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      หมายเลขรายการ
                    </template>
                    <div class="mt-50">
                      WH-519
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      หมายเหตุ
                    </template>
                    <b-form-textarea
                      v-model="remark"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                เลือกล๊อตสินค้า
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      เลือกล๊อตสินค้า
                    </template>
                    <div class="btn-lot mt-n50">
                      <button
                        class="btn-select-lot"
                        @click="selectLot()"
                      >
                        เลือกล๊อตสินค้า
                      </button>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />

                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      คุณได้เลือกล็อต
                    </template>
                    <div class="selct-lot mt-50">
                      <router-link :to="{name : 'admin-warehouse-lot'}">
                        LOT1081
                      </router-link>,
                      <router-link :to="{name : 'admin-warehouse-lot'}">
                        LOT1082
                      </router-link>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
              </b-row>

              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="lotitems"
                :fields="lotfields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
              >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(manage)="data">
                  <i
                    class="far fa-check"
                    @click="checkLot(data.item.id)"
                  />
                </template>

                <template #cell(lot)="data">
                  <small>{{ data.item.id }}LOT1234</small>
                </template>

                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="EditType(data.item)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                รายการที่รับเข้า
              </h3>
            </div>

            <b-row class="mt-1 px-2">
              <!-- <b-col md="4" /> -->
              <b-col md="4 mb-1">
                <div
                  class="d-flex"
                >
                  <p class="mb-0 mr-1">
                    คุณได้เลือกล็อต
                  </p>
                  <div class="selct-lot">
                    <router-link :to="{name : 'admin-warehouse-lot'}">
                      LOT1081
                    </router-link>,
                    <router-link :to="{name : 'admin-warehouse-lot'}">
                      LOT1082
                    </router-link>
                  </div>
                </div>
              </b-col>
              <b-col md="8" />

              <!-- <b-col md="3" /> -->
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <b-form-group class="w-75">
                    <validation-provider
                      #default="{ errors }"
                      name="Parcel Code"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <i class="fal fa-barcode-read" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="h-parcel-code"
                          v-model="parcelCode"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    variant="primary"
                    class="ml-50 w-25 mt-n1"
                    @click="addParcelCode()"
                  >
                    รับพัสดุเข้า
                  </b-button>
                </div>
              </b-col>
              <b-col md="8" />
            </b-row>

            <div class="p-2 mt-n2">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="incomeItems"
                :fields="incomefields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
              >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(lot)="data">
                  <small>{{ data.item.id }}LOT1234</small>
                </template>

                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="EditType(data.item)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>

    <!-- Modal Lot -->
    <b-modal
      id="modal-lot"
      title="Role"
      size="lg"
      hide-footer
      centered
      :visible="modalRole"
      @hide="modalRole = false"
    >
      <b-row class="d-flex align-items-center">
        <b-col md="5">
          <div class="card border">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="checkAll2"
                  value="true"
                  class="custom-control-primary"
                  :indeterminate="isIndeterminate2"
                  @change="checkAllOptions"
                />
                <h6 class="mb-0">
                  <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} items
                </h6>
              </div>

              <h6 class="mb-0">
                รายการล็อต
              </h6>
            </div>

            <div class="p-1">
              <div class="income-order-search order-search">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="searchRight"
                      type="text"
                      placeholder="Search here..."
                      class="anan-input__input"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="role-items">
                <b-form-group class="mt-1">
                  <b-form-checkbox
                    v-for="option in filteredOptions2"
                    :key="option.level"
                    v-model="roleDetail2"
                    :value="option.level"
                    name="flavour-3a"
                  >
                    <div class="mb-1">
                      {{ option.name }}
                    </div>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="2">
          <div class="ant-transfer-operation">
            <button
              type="button"
              class="btn-mover"
              @click="moveToRight"
            >
              <i class="fas fa-arrow-to-left" />
            </button>
            <button
              type="button"
              class="btn-mover"
              @click="moveToLeft"
            >
              <i class="fas fa-arrow-from-left" />
            </button>

          </div>
        </b-col>

        <b-col md="5">
          <div class="card border">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="checkAll"
                  value="true"
                  class="custom-control-primary"
                  :indeterminate="isIndeterminate"
                  @change="checkAllOptions"
                />
                <h6 class="mb-0">
                  <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} items
                </h6>
              </div>

              <h6 class="mb-0">
                ล็อตที่เลือก
              </h6>
            </div>

            <div class="p-1">
              <div class="income-order-search order-search">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="searchLeft"
                      type="text"
                      placeholder="Search here..."
                      class="anan-input__input"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="role-items">
                <b-form-group class="mt-1">
                  <b-form-checkbox
                    v-for="option in filteredOptions"
                    :key="option.level"
                    v-model="roleDetail"
                    :value="option.level"
                    name="flavour-3a"
                  >
                    <div class="mb-1">
                      {{ option.name }}
                    </div>
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { email, required } from '@validations'
import {
  BCol, BFormCheckbox, BFormGroup, BFormInput,
  BFormTextarea,
  BModal,
  BRow,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BModal,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      role: '',
      searchLeft: '',
      searchRight: '',
      checkAll: false,
      checkAll2: false,
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      required,
      email,
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],
      lotfields: [
        {
          key: 'manage', label: 'จัดการ', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot', label: 'ล็อต', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'number', label: 'ลำดับ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'parcelCode', label: 'รหัสพัสดุ', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'inWarehouse', label: 'เข้าโกดัง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'outWarehouse', label: 'ออกโกดัง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'toThai', label: 'ถึงไทย', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: 'ลูกค้า', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: 'จำนวน', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: 'น้ำหนัก', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cbm', label: 'คิว.', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'setup', label: 'จัดวาง', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: '', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      lotitems: [
        {
          id: 1,
          manage: '',
          lot: 'LOT-0001',
          number: '1',
          parcelCode: 'PARCEL-0001',
          inWarehouse: 'W-0001',
          outWarehouse: 'W-0002',
          toThai: 'THAI-0001',
          customer: 'C-0001',
          amount: '1',
          weight: '1',
          cbm: '1',
          setup: '1',
          actions: '',
        },
      ],
      incomefields: [
        {
          key: 'actions', label: 'แก้ไข', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'manage', label: '', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot', label: 'ล็อต', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'number', label: 'ลำดับ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'parcelCode', label: 'รหัสพัสดุ', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'inWarehouse', label: 'เข้าโกดัง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'outWarehouse', label: 'ออกโกดัง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'toThai', label: 'ถึงไทย', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: 'ลูกค้า', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: 'จำนวน', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weight', label: 'น้ำหนัก', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'weightTH', label: 'น้ำหนักไทย', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'cbm', label: 'คิว.', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'setup', label: 'จัดวาง', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: '', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      incomeItems: [
        {
          id: 1,
          manage: 2,
          lot: 'LOT-0001',
          number: '1',
          parcelCode: 'PARCEL-0001',
          inWarehouse: 'W-0001',
          outWarehouse: 'W-0002',
          toThai: 'THAI-0001',
          customer: 'C-0001',
          amount: '1',
          weight: '1',
          cbm: '1',
          setup: '1',
          actions: '',
        },
      ],
    }
  },
  computed: {
    isIndeterminate() {
      return this.roleDetail.length > 0 && this.roleDetail.length < this.optionsLeft.length
    },
    filteredOptions() {
      if (this.searchLeft) {
        const searchTerm = this.searchLeft.toLowerCase()
        return this.optionsLeft.filter(option => option.name.toLowerCase().includes(searchTerm))
      }
      return this.optionsLeft
    },

    isIndeterminate2() {
      return this.roleDetail2.length > 0 && this.roleDetail2.length < this.optionsRight.length
    },
    filteredOptions2() {
      if (this.searchRight) {
        const searchTerm2 = this.searchRight.toLowerCase()
        return this.optionsRight.filter(option => option.name.toLowerCase().includes(searchTerm2))
      }
      return this.optionsRight
    },
  },
  mounted() {
    this.GetSubrole()
  },
  methods: {
    addParcelCode() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          alert('success')
        }
      })
    },
    selectLot() {
      this.$bvModal.show('modal-lot')
    },
    checkAllOptions() {
      if (this.checkAll) {
        this.roleDetail = this.optionsLeft.map(option => option.level)
      } else {
        this.roleDetail = []
      }
      if (this.checkAll2) {
        this.roleDetail2 = this.optionsRight.map(option => option.level)
      } else {
        this.roleDetail2 = []
      }
    },
    moveToLeft() {
      this.optionsLeft = this.optionsLeft.concat(this.optionsRight.filter(option => this.roleDetail2.includes(option.level)))
      this.optionsRight = this.optionsRight.filter(option => !this.roleDetail2.includes(option.level))
      this.roleDetail2 = []
      this.checkAll = false
      this.checkAll2 = false
    },
    moveToRight() {
      this.optionsRight = this.optionsRight.concat(this.optionsLeft.filter(option => this.roleDetail.includes(option.level)))
      this.optionsLeft = this.optionsLeft.filter(option => !this.roleDetail.includes(option.level))
      this.roleDetail = []
      this.checkAll = false
      this.checkAll2 = false
    },
    removeItem(level) {
      this.optionsRight = this.optionsRight.filter(option => option.level !== level)
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
    async GetSubrole() {
      try {
        const { data: res } = await this.$http.get('/moduledetail/index')
        this.optionsRight = res
      } catch (error) {
        console.log(error)
      }
    },
    addRold() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.filteredOptions.length > 0) {
          console.log(this.filteredOptions)
          const subrole = this.filteredOptions.map(option => option.level).join(',')
          const obj = {
            name: this.role,
            sub_role: subrole,
          }
          this.$http
            .post('/modulelist/storeAndupdate', obj)
            .then(() => {
              this.role = ''
              this.searchLeft = ''
              this.searchRight = ''
              this.checkAll = false
              this.checkAll2 = false
              this.roleDetail = []
              this.roleDetail2 = []
              this.Alert(true, 'เพิ่มข้อมูลสำเร็จ', '')
              this.$router.push({ name: 'admin-role' })
            })
            .catch(error => {
              this.Alert(false, 'เพิ่มข้อมูลไม่สำเร็จ', '')
              console.log(error)
              // this.SwalError(error.response.data.message)
            })
        }
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
